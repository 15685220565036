@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* In your global CSS or Tailwind CSS configuration file */
@layer utilities {
  .prose-dark {
    --tw-prose-body: #d1d5db;
    --tw-prose-headings: #ffffff;
    --tw-prose-lead: #9ca3af;
    --tw-prose-links: #60a5fa;
    --tw-prose-bold: #ffffff;
    --tw-prose-counters: #9ca3af;
    --tw-prose-bullets: #6b7280;
    --tw-prose-hr: #4b5563;
    --tw-prose-quotes: #f3f4f6;
    --tw-prose-quote-borders: #9ca3af;
    --tw-prose-captions: #9ca3af;
    --tw-prose-code: #f9fafb;
    --tw-prose-pre-code: #f9fafb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #4b5563;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #ffffff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #60a5fa;
    --tw-prose-invert-bold: #ffffff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #6b7280;
    --tw-prose-invert-hr: #4b5563;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #9ca3af;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-code: #f9fafb;
    --tw-prose-invert-pre-code: #f9fafb;
    --tw-prose-invert-pre-bg: #1f2937;
    --tw-prose-invert-th-borders: #d1d5db;
    --tw-prose-invert-td-borders: #4b5563;
  }
}
